import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { SiGamejolt } from 'react-icons/si';
import '../pages/pages.css'
import { BiTimeFive, BiUserCircle } from 'react-icons/bi';
import { signOut } from 'firebase/auth';
import { auth } from '../config/fire';
import logo from './logojudge.png'

export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function Header() {
    const location = useLocation();
    // function logOut() {
    //     return signOut(auth)
    // } 
    return (
        <>
            {
                getWindowDimensions().width < 600 &&
                <div className="header" >
                    <img src={logo} style={{ height: '40px', width: '60px', marginLeft: '15px' }} /><h1 style={{ margin: '0', color: '#00b7ff', fontWeight: '500', fontSize: '1.3em', minWidth: 'max-content' }}>IKF Judge</h1>
                    <div style={{ flex: '1' }}></div>
                    <div style={{ background: '#00b7ff', fontSize: '0.5em', maxWidth: 'min-content', color: '#000', padding: '5px', borderRadius: '5px', margin: '10px', cursor: 'pointer' }} onClick={() => { signOut(auth) }}>Logout</div>
                </div>
            }
            {
                location.pathname !== '/event' &&
                <div className="header" style={{ top: getWindowDimensions().width < 600 ? '50px' : '0px' }}>
                    {
                        getWindowDimensions().width > 600 &&
                        <>
                            <h1 style={{ margin: '0', color: '#00b7ff', fontWeight: '500', fontSize: '1.3em', minWidth: 'max-content' }}>IKF Judge</h1>
                            <div style={{ flex: '1' }}></div>
                            <div style={{ background: '#00b7ff', fontSize: '0.5em', maxWidth: 'min-content', color: '#000', padding: '5px', borderRadius: '5px', margin: '10px', cursor: 'pointer' }} onClick={() => { signOut(auth) }}>Logout</div>
                        </>
                    }
                    <NavLink exact to='' className={'header_navlink'} activeClassName="active" style={{ width: getWindowDimensions().width > 600 ? '180px' : '100%', height: '100%', display: 'flex', flexDirection: getWindowDimensions().width > 600 ? 'row' : 'column', alignItems: 'center', justifyContent: 'center', fontWeight: 'normal' }} activeStyle={{ display: 'flex', flexDirection: getWindowDimensions().width > 600 ? 'row' : 'column', alignItems: 'center', justifyContent: 'center', color: "#00b7ff" }}>< BiTimeFive style={{ marginRight: getWindowDimensions().width > 600 ? '10px' : '0px', fontSize: '1em' }} /><font style={{ fontSize: '0.8em' }}>Assigned events</font></NavLink>
                    <NavLink exact to='/profile' className={'header_navlink'} activeClassName="active" style={{ width: getWindowDimensions().width > 600 ? '180px' : '100%', height: '100%', display: 'flex', flexDirection: getWindowDimensions().width > 600 ? 'row' : 'column', alignItems: 'center', justifyContent: 'center', fontWeight: 'normal' }} activeStyle={{ display: 'flex', flexDirection: getWindowDimensions().width > 600 ? 'row' : 'column', alignItems: 'center', justifyContent: 'center', color: "#00b7ff" }}>< BiUserCircle style={{ marginRight: getWindowDimensions().width > 600 ? '10px' : '0px', fontSize: '1em' }} /><font style={{ fontSize: '0.8em' }}>Profile</font></NavLink>
                </div>
            }
        </>
    )
}

export default Header
