import {
  addDoc,
  collection,
  doc,
  increment,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { IoAddOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { auth, db } from "../config/fire";
import GiveScore from "../components/GiveScore";
import { v4 } from "uuid";
function EventPage() {
  const history = useHistory();
  const [projectData, setProjectData] = useState(null);
  const [participationgSingers, setParticipationgSingers] = useState(null);
  const [eventKey, setEventKey] = useState(null);
  const [selectedSinger, setSelectedSinger] = useState(null);
  const [scoreOfselected, setScoreOfselected] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isTrue, setIsTrue] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const RetrivedchildKey = queryString.substring(1);
    setEventKey(RetrivedchildKey);
    onSnapshot(doc(db, "events", RetrivedchildKey), (snap) => {
      setProjectData(snap.data());
    });
    onSnapshot(
      query(
        collection(db, "events", RetrivedchildKey, "singers"),
        orderBy("place")
      ),
      (snap) => {
        let temp = [];
        snap.docs.forEach((doc) => {
          temp.push({ ...doc.data(), ref: doc.ref });
        });
        setParticipationgSingers(temp);
      }
    );
  }, []);

  useEffect(() => {
    if (show === false) {
      setScoreOfselected({});
    }
  }, [show]);

  function giveScore(singer, scoreObj) {
    const scoreKey = v4();
    setDoc(doc(db, "events", eventKey, "stats", scoreKey), {
      ...scoreObj,
      givenBy: auth.currentUser.uid,
      forEvent: eventKey,
      forSinger: singer.singer,
      scoreKey,
    });
    setShow(false);
  }
  if (isTrue) {
    return (
      <div
        className="page_container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          minHeight: "calc(100vh - 55px)",
          marginTop: "55px",
        }}
      >
        <div className="header" style={{ top: "55px" }}>
          <BiArrowBack
            onClick={() => {
              history.goBack();
            }}
            style={{ color: "#00b7ff", fontSize: "1.5em", margin: "0px 20px" }}
          />
          <h1
            style={{
              margin: "0",
              color: "#00b7ff",
              fontWeight: "500",
              fontSize: "1.3em",
            }}
          >
            {projectData?.eventName}
          </h1>
          <div style={{ flex: "1" }}></div>
          {!isTrue && (
            <Button
              onClick={() => {
                setIsTrue(true);
              }}
              style={{
                marginTop: "0px",
                color: "#000",
                background: "#00b7ff",
                marginBottom: "0px",
                marginRight: "20px",
              }}
            >
              Lock scores
            </Button>
          )}
        </div>
        <center>
          <h6 style={{ margin: "15px 0px", marginTop: "55px" }}>
            You have locked the scores
          </h6>
        </center>
      </div>
    );
  }
  return (
    <div
      className="page_container"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        minHeight: "calc(100vh - 55px)",
        marginTop: "55px",
      }}
    >
      <Modal className="addLinkModal" show={show} onHide={handleClose}>
        <Modal.Body>
          <h2 style={{ color: "#fff", marginBottm: "10px" }}>
            Give score to {selectedSinger?.singer}
          </h2>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              color: "#fff",
            }}
          >
            <p style={{ margin: "0", color: "#fff" }}>TECHNICAL</p>
            <div>
              <input
                type={"number"}
                onChange={(e) => {
                  if (e.target.value <= 50) {
                    setScoreOfselected({
                      ...scoreOfselected,
                      ["TECHNICAL"]: Number(e.target.value),
                    });
                  } else {
                    e.target.value = 0;
                  }
                }}
                style={{
                  borderRadius: "5px",
                  fontSize: "0.9em",
                  padding: "5px",
                  color: "#fff",
                  background: "#151515",
                  width: "-webkit-fill-available",
                  marginBottom: "5px",
                  width: "50px",
                  textAlign: "center",
                }}
              />
              {"  "}/50
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              color: "#fff",
            }}
          >
            <p style={{ margin: "0", color: "#fff" }}>ARTISTRY</p>
            <div>
              <input
                type={"number"}
                onChange={(e) => {
                  if (e.target.value <= 50) {
                    setScoreOfselected({
                      ...scoreOfselected,
                      ["ARTISTRY"]: Number(e.target.value),
                    });
                  } else {
                    e.target.value = 0;
                  }
                }}
                style={{
                  borderRadius: "5px",
                  fontSize: "0.9em",
                  padding: "5px",
                  color: "#fff",
                  background: "#151515",
                  width: "-webkit-fill-available",
                  marginBottom: "5px",
                  width: "50px",
                  textAlign: "center",
                }}
              />
              {"  "}/50
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <buttn
              onClick={handleClose}
              style={{
                borderRadius: "50vh",
                display: "flex",
                justifyContent: "center",
                fontSize: "0.9em",
                padding: "5px",
                color: "#00b7ff",
                border: "1px solid #00b7ff",
                margin: "10px 5px",
                width: "-webkit-fill-available",
                marginBottom: "0",
              }}
              className="btn"
            >
              cancel
            </buttn>
            <buttn
              onClick={() => {
                let temp = 0;
                for (const property in scoreOfselected) {
                  temp += 1;
                }
                if (temp < 2) {
                  alert("Please provide all the scores");
                } else {
                  giveScore(selectedSinger, scoreOfselected);
                  alert("saved");
                }
              }}
              style={{
                borderRadius: "50vh",
                display: "flex",
                justifyContent: "center",
                fontSize: "1em",
                padding: "5px",
                color: "#000",
                background: "#00b7ff",
                margin: "10px 5px",
                width: "-webkit-fill-available",
                marginBottom: "0",
              }}
              className="btn"
            >
              <IoAddOutline style={{ fontSize: "1.5em", marginRight: "7px" }} />
              submit
            </buttn>
          </div>
        </Modal.Body>
      </Modal>
      <div className="header" style={{ top: "55px" }}>
        <BiArrowBack
          onClick={() => {
            history.goBack();
          }}
          style={{ color: "#00b7ff", fontSize: "1.5em", margin: "0px 20px" }}
        />
        <h1
          style={{
            margin: "0",
            color: "#00b7ff",
            fontWeight: "500",
            fontSize: "1.3em",
          }}
        >
          {projectData?.eventName}
        </h1>
        <div style={{ flex: "1" }}></div>
        {!isTrue && (
          <Button
            onClick={() => {
              setIsTrue(true);
            }}
            style={{
              marginTop: "0px",
              color: "#000",
              background: "#00b7ff",
              marginBottom: "0px",
              marginRight: "20px",
            }}
          >
            Lock scores
          </Button>
        )}
      </div>
      {projectData?.isLive && (
        <>
          <h6 style={{ margin: "15px 0px", marginTop: "55px" }}>
            All participants
          </h6>
          {participationgSingers &&
            participationgSingers.map((singer, i) => {
              return (
                <GiveScore
                  setSelectedSinger={setSelectedSinger}
                  handleShow={handleShow}
                  singer={singer}
                  i={i}
                  eventKey={eventKey}
                />
              );
            })}
        </>
      )}
      {!projectData?.isLive && (
        <center>
          <h2 style={{ color: "#ccc", marginBottm: "10px", marginTop: "55px" }}>
            This event has not started yet
          </h2>
        </center>
      )}
    </div>
  );
}

export default EventPage;
