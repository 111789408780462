
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { ref as sRef } from 'firebase/storage';
import { getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { auth, db, storage } from '../config/fire'
import React, { useState, useEffect, useRef } from 'react'

function Profile() {
    const [userData, setUserData] = useState(null)
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [bio, setBio] = useState('')
    const [dob, setDob] = useState('')
    const [profileLoading, setProfileLoading] = useState(false)
    const hiddenBrowseButton = useRef(null)
    const [thumbnail, setThumbnail] = useState('https://questhowth.ie/wp-content/uploads/2018/04/user-placeholder.png')
    useEffect(() => {
        onSnapshot(doc(db, 'users', auth.currentUser.uid, 'user_details', 'info'), (snap) => {
            console.log(snap.data());
            setUserData(snap.data())
            setName(snap.data()?.name ? snap.data()?.name : '')
            setAddress(snap.data()?.address ? snap.data()?.address : '')
            setBio(snap.data()?.bio ? snap.data()?.bio : '')
            setDob(snap.data()?.dob ? snap.data()?.dob : '')
            setPhone(snap.data()?.phone ? snap.data()?.phone : '')
            setThumbnail(snap.data()?.thumbnail ? snap.data()?.thumbnail : 'https://questhowth.ie/wp-content/uploads/2018/04/user-placeholder.png')
        })
    }, [])
    async function uploadProfilePicture(e) {
        setProfileLoading(true)
        const imageFile = e.target.files[0];
        try {

            const imageOne = imageFile;
            const storageRef = sRef(storage, `images/${imageOne.name}`);

            const uploadTask = uploadBytesResumable(storageRef, imageOne);
            uploadTask.on('state_changed',
                (snapshot) => {
                },
                (error) => {
                    alert(error)
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        setProfileLoading(false)
                        setThumbnail(downloadURL)
                    });
                }
            );

        } catch (error) {
            alert(error)
            setProfileLoading(false)
        }
    };
    function saveChanges() {
        updateDoc(doc(db, 'users', auth.currentUser.uid, 'user_details', 'info'), {
            name,
            thumbnail,
            bio,
            dob,
            address,
            phone
        }).then(() => {
            alert('Saved');
            console.log({
                name,
                thumbnail,
                bio,
                dob,
                address,
                phone
            });
        })
    }
    return (
        <div className="page_container" style={{ padding: '0' }}>
            <div style={{ maxWidth: '400px' }}>
                <div style={{ borderRadius: '10px', background: '#000', width: '-webkit-fill-available', padding: '24px', height: 'max-content' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ flex: '.4' }}>

                            {!profileLoading ?
                                <img style={{ height: '100px', width: '100px', borderRadius: '50vh' }}
                                    src={thumbnail}
                                />
                                :
                                <div style={{ height: '100px', width: '100px', borderRadius: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    loading
                                </div>
                            }
                        </div>
                        <div style={{ flex: '.6' }}>
                            <input
                                ref={hiddenBrowseButton} onChange={(e) => uploadProfilePicture(e)}
                                type="file" style={{ "display": "none" }} />
                            <buttn
                                onClick={() => hiddenBrowseButton.current.click()}
                                style={{ borderRadius: '50vh', fontSize: '1em', padding: '8px 15px', color: '#000', background: '#00b7ff', margin: '10px', width: '-webkit-fill-available', marginBottom: '0' }} className='btn'>
                                Pick an image
                            </buttn>
                            <buttn
                                onClick={() => { setThumbnail('https://questhowth.ie/wp-content/uploads/2018/04/user-placeholder.png') }}
                                style={{ borderRadius: '50vh', fontSize: '1em', padding: '8px 15px', color: '#00b7ff', border: '1px solid #00b7ff', margin: '10px', width: '-webkit-fill-available' }} className='btn'>
                                Remove
                            </buttn>
                        </div>
                    </div>
                    <br></br>
                    <p style={{ margin: '0', color: '#6d6d6d' }}>Email</p>
                    <input type={'text'}
                        value={auth.currentUser.email} disabled
                        style={{ borderRadius: '5px', fontSize: '1em', padding: '8px 15px', color: '#fff', textTransform: 'capitalize', background: '#151515', width: '-webkit-fill-available', marginBottom: '10px' }} />
                    <p style={{ margin: '0', color: '#6d6d6d' }}>Name</p>
                    <input type={'text'}
                        value={name} onChange={(e) => { setName(e.target.value) }}
                        style={{ borderRadius: '5px', fontSize: '1em', padding: '8px 15px', color: '#fff', textTransform: 'capitalize', background: '#151515', width: '-webkit-fill-available', marginBottom: '10px' }} />
                    <p style={{ margin: '0', color: '#6d6d6d' }}>Phone</p>
                    <input type={'number'}
                        value={phone} onChange={(e) => { setPhone(e.target.value) }}
                        style={{ borderRadius: '5px', fontSize: '1em', padding: '8px 15px', color: '#fff', textTransform: 'capitalize', background: '#151515', width: '-webkit-fill-available', marginBottom: '10px' }} />
                    <p style={{ margin: '0', color: '#6d6d6d' }}>Address</p>
                    <input type={'text'}
                        placeholder='City, State/Province, Country'
                        value={address} onChange={(e) => { setAddress(e.target.value) }}
                        style={{ borderRadius: '5px', fontSize: '1em', padding: '8px 15px', color: '#fff', textTransform: 'capitalize', background: '#151515', width: '-webkit-fill-available', marginBottom: '10px' }} />
                    <p style={{ margin: '0', color: '#6d6d6d' }}>Date of birth</p>
                    <input type={'text'}
                        value={dob} onChange={(e) => { setDob(e.target.value) }}
                        style={{ borderRadius: '5px', fontSize: '1em', padding: '8px 15px', color: '#fff', textTransform: 'capitalize', background: '#151515', width: '-webkit-fill-available', marginBottom: '10px' }} />
                    <p style={{ margin: '0', color: '#6d6d6d' }}>Bio</p>
                    <textarea rows={'4'}
                        value={bio} onChange={(e) => { if (e.target.value.length <= 50) { setBio(e.target.value) } }}
                        type={'text'} style={{ borderRadius: '5px', fontSize: '1em', padding: '8px 15px', color: '#fff', background: '#151515', width: '-webkit-fill-available', marginBottom: '0' }} />
                    {
                        (userData?.name !== name || userData?.bio !== bio || userData?.phone !== phone || userData?.dob !== dob || userData.address !== address) &&
                        <buttn onClick={() => {
                            saveChanges()
                        }} style={{ borderRadius: '50vh', fontSize: '1em', padding: '8px 15px', color: '#000', background: '#00b7ff', width: '-webkit-fill-available', margin: '0', marginTop: '20px' }} className='btn'>
                            Save changes
                        </buttn>
                    }
                </div>
            </div>
        </div>
    )
}

export default Profile