import React from "react";
import './App.css';
import Header from "./components/Header";
import Login from './pages/Login/Login';
import { auth } from './config/fire'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import EventPage from "./pages/EventPage";
function App() {
  const [user] = useAuthState(auth);
  return (
    <>
      {user === null ?
        <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'centers' }}>
          <Login />
        </div>
        :
        <Router>
          <div className="app">
            <div className="app__header">
              <Header />
            </div>
            <div className="app__body">
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/profile">
                  <Profile />
                </Route>
                <Route exact path="/event">
                  <EventPage />
                </Route>
              </Switch>
            </div>
          </div>
        </Router>}
    </>
  );
}

export default App;
