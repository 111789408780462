import React, { useState, useEffect, useRef } from 'react';
import { IoAddOutline } from 'react-icons/io5'
import Modal from 'react-bootstrap/Modal';
import { collection, doc, onSnapshot, setDoc, Timestamp } from 'firebase/firestore';
import { auth, db } from '../config/fire';
import { uuidv4 } from '@firebase/util';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
export default function Home() {
    const nameRef = useRef(null)
    const addressRef = useRef(null)
    const [eventDate, setEventDate] = useState(null)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [allEvents, setAllEvents] = useState(null)

    useEffect(() => {
        onSnapshot(collection(db, 'events'), (snap) => {
            let temp = []
            snap.docs.forEach(doc => {
                temp.push(doc.data())
            })
            setAllEvents(temp)
        })
    }, [])

    function addEvent(obj) {
        let eventId = uuidv4()
        setDoc(doc(db, 'events', eventId), { ...obj, eventId })
    }
    return (
        <div className="page_container">
            <h6 style={{ marginBottom: '15px' }}>Assigned events</h6>
            <div className='card_container'>
                {
                    allEvents && allEvents.map(data => {
                        if (data?.judges?.includes(auth.currentUser.uid)) {
                            return (
                                <div style={{ borderRadius: '10px', background: '#101010', width: '-webkit-fill-available', padding: '24px', height: 'max-content', marginBottom: '10px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                        <div style={{ flex: '1' }}>
                                            <h5>Name: {data.eventName}</h5>
                                            <h6 style={{ color: '#ccc' }}>Venue: {data.address}</h6>
                                            <h6 style={{ color: '#ccc' }}>Date: {new Date(data.date).toLocaleDateString()}</h6>
                                        </div>
                                    </div>
                                    <Link to={{ pathname: `/event`, search: `?${data.eventId}`, state: { fromDashboard: true } }} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <Button style={{ width: '-webkit-fill-available', backgroundColor: '#00b7ff', color: '#000', marginTop: '10px' }}>View more</Button>
                                    </Link>
                                </div>
                            )
                        }
                    })
                }
            </div>
        </div>
    );
}