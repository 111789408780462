import { collection, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { auth, db } from '../config/fire';

function GiveScore({ setSelectedSinger, handleShow, singer, i, eventKey }) {
    const [given, setGiven] = useState(false)
    useEffect(() => {
        // addDoc(collection(db, 'events', eventKey, 'stats'), { ...scoreObj, givenBy: auth.currentUser.uid, forEvent: eventKey, forSinger: singer.singer })
        onSnapshot(query(collection(db, 'events', eventKey, 'stats'), where('givenBy', '==', auth.currentUser.uid), where('forSinger', '==', singer.singer)), (snap) => {
            if (!snap.empty) {
                setGiven(true)
            }
        })
    }, [])

    return (
        <div style={{ marginBottom: '15px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', background: '#00b7ff30', padding: '10px 15px', borderRadius: '10px' }}>
            <div style={{ display: 'flex' }}>
                <h1 style={{ margin: '0', marginRight: '20px' }}>{i + 1}</h1>
                <div>
                    <p style={{ fontSize: '1em', fontWeight: '500', color: '#fff', margin: '0', marginBottom: '0' }}>Name: {singer.singer}</p>
                    <p style={{ fontSize: '0.8em', fontWeight: '500', color: '#ccc', margin: '0', marginBottom: '0' }}>Song: {singer.song}</p>
                </div>
            </div>
            {
                !given ?
                    <Button onClick={() => { setSelectedSinger(singer); handleShow() }} style={{ marginTop: '15px', color: '#000', background: '#00b7ff', marginBottom: '5px' }}>Give Scores</Button>
                    :
                    <Button disabled style={{ marginTop: '15px', color: '#000', background: '#00000000', marginBottom: '5px', color: '#00b7ff' }}>Score given</Button>

            }
        </div>
    )
}

export default GiveScore